<template>
  <loading-dialog :loading="$apollo.loading">
    <v-card v-if="emailCredentialsAndChannelEmailExists" class="pa-2">
      <v-card-title>Configuração de Email e DNS</v-card-title>

      <!-- email_credentials -->
      <v-row no-gutters>
        <v-col cols="6">
          <v-card flat>
            <v-card-text>
              <label class="subheading">Conta da Infobip:</label>
              <v-text-field v-model="emailCredentials.login" :disabled="!editable" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card flat>
            <v-card-text>
              <label class="subheading">Senha da Infobip:</label>
              <v-text-field v-model="emailCredentials.password" :disabled="!editable" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- channels.email -->
      <v-row no-gutters>
        <v-col cols="6">
          <v-card flat>
            <v-card-text>
              <label class="subheading">E-mail do remetente:</label>
              <v-text-field v-model="emailChannels.domain" :disabled="!editable" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card flat>
            <v-card-text>
              <label class="subheading">Nome do Remetente:</label>
              <v-text-field v-model="emailChannels.sender_name" :disabled="!editable" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card flat>
            <v-btn :disabled="!editable" class="py-5 mt-10" color="primary" outlined @click="showTestEmail = true"
              ><v-icon class="mb-1 mr-2">mdi-email-fast-outline</v-icon> Enviar E-mail de teste</v-btn
            >
          </v-card>
        </v-col>
      </v-row>

      <!-- price -->
      <v-row>
        <v-col cols="6">
          <v-card flat>
            <v-card-text>
              <label class="subheading">Preço do crédito:</label>
              <v-text-field v-model="price" :disabled="!editable" class="mb-0" outlined prefix="R$" suffix="/crédito" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- buttons -->
      <v-row no-gutters>
        <btn-card-actions class="px-2 pt-0" :editable.sync="editable" :saving="saving" @save="saveEdit" @cancel="cancelRestore" />
        <v-btn v-if="!editable" color="primary" class="mb-0 mt-2" outlined @click="addZoneDialog = true"
          ><v-icon class="mr-1">mdi-plus</v-icon> Adicionar Zona</v-btn
        >
        <v-btn v-if="!editable" color="primary" class="mb-0 ml-4 mt-2" outlined @click="openTsvModal"
          ><v-icon class="mr-1">mdi-cog</v-icon> Configurar DNS Record</v-btn
        >
      </v-row>
    </v-card>
    <v-card v-else flat class="pa-8">
      <v-row>
        <v-col cols="12">
          <div class="email-buttons">
            <v-btn v-if="editable" color="primary" block class="mb-0" outlined @click="zoneModal = true">Configurar Cloudflare Zone</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- modals -->
    <cloudflare-zone :dialog="zoneModal" :accountId="accountId" @close="closeModal" />
    <tsv-modal :dialog="tsvModal" :zoneList="zoneList" :accountId="accountId" @close="closeModal" />
    <test-email
      v-if="showTestEmail"
      :dialog="showTestEmail"
      :credentials="emailCredentials"
      :domain="emailChannels.domain"
      :sender-name="emailChannels.sender_name"
      @close="closeTestEmail"
    />

    <!-- add zone dialog -->
    <v-dialog v-model="addZoneDialog" max-width="400">
      <v-card>
        <v-card-title><v-icon class="mx-auto" x-large>mdi-alert-circle</v-icon></v-card-title>
        <v-card-text class="text-center"
          >Os parâmetros para configuração serão utilizados de acordo com o campo "<strong>domain</strong>", certifique-se de que o nome do domínio está
          correto.</v-card-text
        >
        <v-card-actions class="justify-center">
          <v-btn color="red darken-1" outlined @click="setZone"> <v-icon class="mr-1">mdi-alert</v-icon>Continuar</v-btn>
          <v-btn color="green darken-1" outlined @click="addZoneDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </loading-dialog>
</template>

<script>
import { MUTATE_EMAIL_CONFIG_PARAMS, MUTATE_ADD_CLOUDFLARE_ZONE, QUERY_GET_CLOUDFLARE_ZONES } from '@/modules/accounts/graphql'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue'),
    CloudflareZone: () => import('@/modules/accounts/partials/CloudflareZone.vue'),
    TestEmail: () => import('@/modules/accounts/partials/TestEmail.vue'),
    TsvModal: () => import('@/modules/accounts/partials/TsvModal.vue'),
    BtnCardActions: () => import('@/components/base/BtnCardActions')
  },
  props: {
    account: {
      type: Object
    }
  },
  data: () => ({
    accountId: '',
    editable: false,
    zoneModal: false,
    addZoneDialog: false,
    tsvModal: false,
    showTestEmail: false,
    emailCredentialsAndChannelEmailExists: null,
    emailCredentials: {
      login: null,
      password: null
    },
    emailChannels: {
      domain: null,
      sender_name: null
    },
    price: '',
    zoneList: {
      name_servers: null,
      original_name_servers: null
    },
    saving: false
  }),
  watch: {
    account(val) {
      if (val) {
        this.getCredentialsFromAccount(val)
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
    if (this.account) {
      this.getCredentialsFromAccount(this.account)
    }
  },
  methods: {
    getCredentialsFromAccount(adminAccount) {
      this.emailCredentials.login = adminAccount?.email_credentials?.login || ''
      this.emailCredentials.password = adminAccount?.email_credentials?.password || ''
      this.emailChannels.domain = adminAccount?.channels?.email?.domain || ''
      this.emailChannels.sender_name = adminAccount?.channels?.email?.sender_name || ''
      this.price = adminAccount?.prices?.credits?.email || ''

      if (adminAccount?.email_credentials || adminAccount?.channels?.email) this.emailCredentialsAndChannelEmailExists = true
    },
    async saveEdit() {
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATE_EMAIL_CONFIG_PARAMS,
          variables: {
            id: this.accountId,
            login: this.emailCredentials.login,
            password: this.emailCredentials.password,
            domain: this.emailChannels.domain,
            sender_name: this.emailChannels.sender_name,
            price: +this.price
          }
        })
        this.$apollo.queries.adminAccount.refresh()
        this.editable = false
        this.$snackbar({ message: 'Configuração salva com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err)
        this.$snackbar({ message: 'Falha ao editar configuração de email', snackbarColor: '#F44336' })
      }
      this.saving = false
    },
    async setZone() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATE_ADD_CLOUDFLARE_ZONE,
          variables: {
            id: this.accountId
          }
        })
        this.addZoneDialog = false
        this.$snackbar({ message: 'Zona criada', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err)
        this.$snackbar({ message: 'Falha ao criar zona ou zona já criada', snackbarColor: '#F44336' })
      }
    },
    async getZones() {
      try {
        const { data } = await this.$apollo.query({
          query: QUERY_GET_CLOUDFLARE_ZONES,
          variables: {
            id: this.accountId
          }
        })
        this.zoneList.name_servers = data.adminGetZoneList.name_servers
        this.zoneList.original_name_servers = data.adminGetZoneList.original_name_servers
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao buscar zonas', snackbarColor: '#F44336' })
      }
    },
    async openTsvModal() {
      await this.getZones()
      this.tsvModal = true
    },
    closeModal() {
      this.zoneModal = false
      this.tsvModal = false
      this.$emit('refresh')
    },
    closeTestEmail() {
      this.showTestEmail = false
    },
    cancelRestore() {
      this.$emit('refresh')
      this.editable = false
    }
  }
}
</script>
